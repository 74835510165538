// Base form controls
//
// Overrides for common inputs for easier styling.

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

label {
  // font-size: 13px;
  // font-weight: bold;
  outline: none;
  &:focus{
     outline: none;
  }
}

// input[type="text"],
// input[type="password"],
// input[type="email"],
// input[type="number"],
// input[type="tel"],
// input[type="url"],

input[type="text"], input[type="password"] , input[type="email"], input[type="number"], input[type="tel"], input[type="url"]{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control{
  // height: 38px;
  // padding: 7px 8px;
  // font-size: 13px;
  // color: #333;
  vertical-align: middle;
  background-color: #fff;
  background-repeat: no-repeat; // Repeat and position set for form states (success, error, etc)
  background-position: right center;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  display: block;
  width: 100%;
  transition: all ease .1s;
  &.focus,
  &:focus {
    // border-color: $blue;
    box-shadow: 0 0 5px rgba(81, 167, 232, 0.5);
  }
}

textarea{
  resize:none;
  height: auto;
}

.form-group {
  margin-bottom: 15px;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  // margin-right: $small-spacing / 2;
}

// Inputs with contrast for easy light gray backgrounds against white.
// input.class is needed here to increase specificity over input[…]
input.input-contrast,
.input-contrast {
  background-color: #fafafa;

  &:focus { background-color: #fff; }
}

// Custom styling for HTML5 validation bubbles (WebKit only)
::placeholder {
  color: #aaa;
}

button,input[type="submit"]{
  &:focus,
  &.focus{
    outline: 0;
  }
}

//placeholder
.form-box {
  input:-moz-placeholder {
    opacity: 1; /* Firefox 18- */
    color: #333 !important;
  }
  input::-moz-placeholder {
  opacity: 1;  /* Firefox 19+ */ 
    color: #333 !important;
  }
  ::-webkit-input-placeholder {
    color: #333 !important;
    opacity: 1 !important; /* for chrome */
  }
  textarea:-moz-placeholder {
    opacity: 1; /* Firefox 18- */ 
    color: #333 !important;
  }
  textarea::-moz-placeholder {
    opacity: 1; /* Firefox 19+ */ 
    color: #333 !important;
  }
  ::-webkit-input-placeholder {   
    opacity: 1 !important; /* for chrome */
     color: #333 !important;
  }
}

.form-field {
  background: #FFFFFF;
  border: 0.5px solid #cbc6c6;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #6C757D;
  padding: 4px 8px;
  &:focus,
  &.focus{
    outline: 0;
    box-shadow: none;
    border: 0.5px solid #cbc6c6;
  }
}

.custom-select {
  position: relative;
  .form-field {
    padding-right: 22px;
  }
  &::after {
    content: "";
    box-sizing: content-box;
    display: inline-block;
    float: right;
    width: 0;
    height: 0;
    margin: 0;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #c4c4c4 transparent transparent transparent;
    position: absolute;
    right: 8px;
    top: 12px;
    pointer-events: none;
  }
}

.custom-range {
  .color-box {
      background: linear-gradient(90deg, rgba(229, 231, 235, 0) 0%, rgba(229, 231, 235, 0.68) 35.94%, #56C4F5 68.75%, #C156C2 100%);
      border-radius: 100px;
      width: 100%;
      height: 6px;
      display: block;
      position: absolute;
      top: 8px;
  }
  input[type=range] {
      position: relative;
      -webkit-appearance: none;
      width: 100%;
      height: 6px;
      background: transparent;
      outline: none;
      -webkit-transition: .2s;
      transition: opacity .2s;
    }
    
  input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 10px;
      height: 10px;
      background: transparent;
      cursor: pointer;
      border-radius: 50px;
      border: 2px solid #DFDFDF;
      box-sizing: border-box;
      z-index: 9;
  }
  input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 10px;
      height: 10px;
      background: transparent;
      cursor: pointer;
      border-radius: 50px;
      border: 2px solid #DFDFDF;
      box-sizing: border-box;
      z-index: 9;
  }
  .output {
      color: #6C757D;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      padding: 5px 8px;
      background: #FFFFFF;
      border: 0.5px solid #CBC6C6;
      box-sizing: border-box;
      border-radius: 2px;
  }
}



.custom-range-1 {
  position: relative;
  .color-box {
    background: linear-gradient(270deg, #9CA3AF 0%, #E5E7EB 100%);
    border-radius: 100px;
      width: 100%;
      height: 6px;
      display: block;
      position: absolute;
      top: 8px;
      &::before {
        content: '';
        background: url('../../img/Ticks.svg');
        position: absolute;
        width: 100%;
        height: 2px;
        top: -8px;
      }
  }
  input[type=range] {
      position: relative;
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      background: transparent;
      outline: none;
      -webkit-transition: .2s;
      transition: opacity .2s;
    }
    
  input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 10px;
      height: 14px;
      background: #fff;
      cursor: pointer;
      border: 2px solid #DFDFDF;
      // box-sizing: border-box;
      z-index: 9;
      border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
      -webkit-transform: rotate(360deg);
      position: relative;
  }

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 14px;
    background: #fff;
    cursor: pointer;
    border: 2px solid #DFDFDF;
    // box-sizing: border-box;
    z-index: 9;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    -webkit-transform: rotate(360deg);
    position: relative;
  }

  input[type='range']::-webkit-slider-thumb:before {
    position: absolute;
    content: '+';
    height: 10px; /* equal to height of runnable track */
    width: 10px; /* make this bigger than the widest range input element */
    left: 0; /* this should be -2px - width */
    top: 0; /* don't change this */
    background: red;
  }
  input[type='range']::-moz-range-thumb:before {
    position: absolute;
    content: '+';
    height: 10px; /* equal to height of runnable track */
    width: 10px; /* make this bigger than the widest range input element */
    left: 0; /* this should be -2px - width */
    top: 0; /* don't change this */
    background: red;
  }

  .output {
      color: #6C757D;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      padding: 5px 8px;
      background: #FFFFFF;
      border: 0.5px solid #CBC6C6;
      box-sizing: border-box;
      border-radius: 2px;
  }
}

/* custom radio starts */

.custom-radio {
  display: block;
  position: relative;
  padding-left: 15px;
  padding-right: 0px !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  box-sizing: content-box;
  background-color: $theme-blue;
  border: 3px solid #fff;
  top: 0px;
  left: 0px;
  height: 7px;
  width: 7px;
}

.custom-radio .checkmark:after {
  display: none;
}

.custom-radio input:checked~.checkmark {
  border: 1px solid $theme-blue;
}

.custom-radio input:checked~.checkmark:after {
  display: block;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #737373;
  border-radius: 50%;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}



/* custom radio ends */
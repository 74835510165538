html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none; }

*:hover {
  outline: none !important; }
*:active {
  outline: none !important; }
*:focus {
  outline: none !important; }
*:visited {
  outline: none !important; }

body::-webkit-scrollbar {
  width: 5px; }

body::-webkit-scrollbar-track {
  background: #f8f8f8;
  border-radius: 10px; }

body::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
  cursor: pointer; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  overflow-x: hidden; }

ol,
ul {
  list-style: none;
  padding: 0px;
  margin: 0px; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: none;
  padding: 0px; }
  textarea:focus {
    padding: 0px !important; }

.relative {
  position: relative !important; }
  a:hover {
    text-decoration: none; }
  
  input {
    outline: none;
    box-shadow: none !important; }
    input:focus {
      outline: none !important;
      box-shadow: none !important;
      border-color: unset; }
  
  .form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
    padding: unset; }
    .object-cover{
      width:100%;
      height: 100%;
      object-fit: cover;
    }
    .object-fill{
      width:100%;
      height: 100%;
      object-fit: fill;
    }
    .mr-0{margin-right:0px !important;}
    .ml-0{margin-left:0px !important;}



    ::-webkit-scrollbar {
      width: 5px; }
    
  ::-webkit-scrollbar-track {
      background: #f8f8f8;
      border-radius: 10px; }
    
    ::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 10px;
      cursor: pointer; }
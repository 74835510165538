.create-modal{
    max-width: 60%;
    .modal-body{
        padding:25px 50px;
        @media (max-width: 767px){
            padding: 15px;
        }
    }
    @media (max-width: 991px){
        max-width: 90%;
    }
    @media (max-width: 767px){
        max-width: 100%;
    }
}
.modal-close{
    font-size: 28px;
    font-weight: 300;
    color:#333;
    line-height: 30px;
    cursor: pointer;
    &:hover{color:#7b7b7b;}
}
.modal-caption{
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    @media (max-width: 767px){
        font-size: 20px;
        line-height: 24px;
    }
}
.modal-subcaption{
    font-size: 16px;
    line-height: 28px;
    color:$panel-grey-txt;
    @media (max-width: 767px){
        font-size: 13px;
        line-height: 18px;
    }
}
.edit-brand-modal{
    max-width: 75%;
    .modal-body{padding:25px 50px;}
    @media (max-width: 991px){
        max-width: 100%;
        padding: 15px;
    }
}
.modal-close svg{fill:#444;}
.custom-modal .modal-content{
    overflow: hidden;
    @include border-radius(10px);
    background: none;
}

.temp-modal{
    max-width: 75%;
    .modal-body{
        padding:15px;
        @media (max-width: 767px){
            padding: 15px;
        }
    }
    @media (max-width: 991px){
        max-width: 90%;
    }
    @media (max-width: 767px){
        max-width: 100%;
    }
}


// 15-11

// edit-modal
.edit-modal {
    max-width: 75%;
    @media screen and (min-width:1200px){
        max-width: 1000px;
    }
    @media screen and (max-width:767px){
        width: 100%;
        max-width: 100%;
        padding: 15px;
        margin: 0;
    }
    .modal-body {
        padding: 24px;
    }
    .img-block {
        flex: 1;
        margin-right: 28px;
        @media screen and (max-width:991px){
            margin-right: 0;
            width: 100%;
            margin-bottom: 15px;
        }
        img {
            width: 100%;
            max-height: 320px;
        }
    }
    .img-more {
        flex: 0 0 335px;
        max-width: 335px;
        @media screen and (max-width:991px){
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        .footer-btn button {
            min-width: 134px;
            min-height: 40px;
        }
    }
    .label-block {
        flex: 0 0 72px;
        max-width: 72px;
        p {
            color: $light-gray-text;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
    .field-block {
        flex: 1;
        svg {
            stroke: $light-gray-text;
        }
        small {
            color: $light-gray-text;
            font-size: 10px;
            line-height: 16px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
}

.edit-modal.dark {
    .label-block p,
    .field-block small {
        color: $dark-grey-font;
    }
    .custom-range-1 .output,
    .custom-range .output  {
        background: transparent;
        color: $dark-grey-font;
    }
    .field-block svg {
        stroke: $dark-grey-font;
    }
    .editor-btn {
        border: 0.5px solid #2E2E2E;
        background: transparent;
    }
    .divider {
        background: #2E2E2E;
    }
}


// view-modal
.view-modal {
    max-width: 75%;
    @media screen and (max-width:767px){
        width: 100%;
        max-width: 100%;
        padding: 15px;
        margin: 0;
    }
    .modal-body {
        padding: 0;
    }
    .img-block {
        img {
            width: 100%;
        }
    }
}



// audio-modal
.audio-modal {
    position: fixed;
    z-index: 1060; /* 1 */
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .modal-overlay {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: hsla(0, 0%, 0%, 0.5);
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 0.3s, opacity 0.3s;
    }
    .modal-wrapper {
        position: absolute;
        z-index: 9999;
        top: 6em;
        left: 50%;
        width: 32em;
        margin-left: -16em;
        background-color: #fff;
        box-shadow: 0 0 1.5em hsla(0, 0%, 0%, 0.35);
        border-radius: 10px;
        @media screen and (max-width: 767px) {
            width: 100%;     
            left: 0;
            right: 0;
            margin: 0px;           
        }
    }   
    .modal-content {
        .img-block {
            z-index: 1061;
            img {
                width: 100%;
            }
        }
        .audio-sec {
            position: relative;
            margin: 0 0 15px;
            .play-audio {
                position: absolute;
                left: 0;
                top: 12px;
            }
            .pause-btn {
                position: absolute;
                left: 0;
                top: 12px;
            }
            .sound-audio {
                position: absolute;
                right: 0;
                top: 12px;
            }
            svg {
                fill: $light-gray-text;
            }
            .audio-wrap {
                display: block;
                margin: 0 35px;
            }
        }
    }
    .footer-btn {
        button {
            text-transform: uppercase;
            min-height: 40px;
            min-width: 134px;
            svg {
                stroke: $light-gray-text;
            }
            &.upload-btn {
                svg {
                    margin-right: 6px;
                }
                span {
                    padding-left: 6px;
                    border-left: 1px solid #a5a5a5;
                }
            }
        }
    }   
    .modal-transition {
        transition: all 0.3s 0.12s;
        transform: translateY(-10%);
        opacity: 0;
    }
    &.is-visible {
        visibility: visible;
        .modal-overlay {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
        .modal-transition {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
.audio-upload{
    top: 50%;
    left: 50%;
    visibility: unset !important;
    transform: translate(-50%, -50%) !important;
    .img-block{
        video {
            z-index: 1061;
        }
    }
}

.audio-modal.dark {
    .modal-wrapper {
        background: #1F2021;
    }
    .modal-content {
        background: #1F2021;
        .audio-sec svg {
            fill: $dark-grey-font;
        }
    }
    .time-duration {
        color: $dark-grey-font;
    }
    .upload-btn {
        background: transparent !important;
        color: $dark-grey-font;
    }
    .btn-outline {
        background: #2E2E2E;
        border: 1px solid #2E2E2E;
        color: $dark-grey-font;
    }
    .footer-btn button.upload-btn span {
        border-left: 1px solid $dark-grey-font;
    }
    .footer-btn button svg {
        stroke: $dark-grey-font;;
    }
}


video::-webkit-media-controls-panel{
     display: none !important;
     opacity: 0 !important;
}



// 1
.toggle-wrap {
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
    float: left;
    
    -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: none; 
    @media screen and (max-width: 991px) {
        display: block;        
    }
  }
  .toggle-bar,
  .toggle-bar::before,
  .toggle-bar::after,
  .toggle-wrap.active .toggle-bar,
  .toggle-wrap.active .toggle-bar::before,
  .toggle-wrap.active .toggle-bar::after {
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
  }
  .toggle-bar {
      width: 26px;
      margin: 10px 0;
      position: relative;
      border-top: 2px solid #303030;
      display: block;
  }
  .toggle-bar::before,
  .toggle-bar::after {
      content: "";
      display: block;
      background: #303030;
      height: 2px;
      width: 26px;
      position: absolute;
      top: -12px;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -ms-transform-origin: 13%;
      -webkit-transform-origin: 13%;
      transform-origin: 13%;
  }
  .toggle-bar::after {
    top: 8px;
  }
  .toggle-wrap.active .toggle-bar {
    border-top: 2px solid transparent;
  }
  .toggle-wrap.active .toggle-bar::before {
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -6px;
  }
  .toggle-wrap.active .toggle-bar::after {
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
  }

  .close-priview {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background: #fff;
    border-radius: 5px;
    svg {
        fill: #000;
    }
}





.replace-modal {
    max-width: 75%;
    @media screen and (max-width:767px){
        width: 100%;
        max-width: 100%;
        padding: 15px;
        margin: 0;
    }
    .modal-content {
        // background: $dark-grey-bg;
        background: #fff;
        border-radius: 6px !important;
    }
    .replace-img {
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        max-height: 126px;
        .icon-wrap {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            background: #5161708f;
            -webkit-transition: all 0.4s ease-in-out 0s;
            -moz-transition: all 0.4s ease-in-out 0s;
            transition: all 0.4s ease-in-out 0s;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                margin-right: 0;
                cursor: pointer;
                &.img-view {
                    background: rgba(0, 0, 0, 0.6);
                    opacity: 0.6;
                    box-shadow: inset 5.6px -5.6px 5.6px rgba(53, 106, 176, 0.146), inset -5.6px 5.6px 5.6px rgba(255, 255, 255, 0.146);
                    backdrop-filter: blur(5.04px);
                    border-radius: 6px 0px 0px 6px;
                    &:hover {
                        background: rgba(70, 139, 232, 0.806);
                    }
                }
                &.img-edit {
                    background: rgba(0, 0, 0, 0.6);                    
                    opacity: 0.6;
                    box-shadow: inset 5.6px -5.6px 5.6px rgba(53, 106, 176, 0.146), inset -5.6px 5.6px 5.6px rgba(255, 255, 255, 0.146);
                    backdrop-filter: blur(5.04px);
                    border-radius: 0px 6px 6px 0px;
                    &:hover {
                        background: rgba(70, 139, 232, 0.806);
                    }
                }
                svg {
                    fill: #fff;
                }
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:hover {
            .icon-wrap {
                opacity: 1;
            }
        }
    }
    .srch-temp-blk {
        position: relative;
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: #6C757D;
            position: absolute;
            right: 0;
            margin-bottom: 0;
            @media screen and (max-width:1199px){
                font-size: 12px;
            }
            @media screen and (max-width:767px){
                position: relative;
            }
            a {
                font-weight: 600;
                text-decoration-line: underline;
                color: $theme-blue;
            }
        }

    }
}

#view-img {
    z-index: 9999 !important;
}

.dark {
    .toggle-bar {
        border-top: 2px solid #c0c2c3;
    }
    .toggle-bar::before, .toggle-bar::after {
        background: #c0c2c3;
    }
}
.styles-modal {
    @media (min-width: 992px){
        max-width: 670px !important;
    }
    .modal-body {
        padding: 35px;
    }
    .style-wraper {
        .style-modal-head {
            margin-bottom: 35px;
            h3 {
                font-weight: 600;
                font-size: 32px;
                line-height: 39px;
                color: #F5F5F5;
                margin-bottom: 20px;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: #C0C2C4;
                margin-bottom: 0;
            }
        }
        .style-inner {
            max-width: 390px;
            margin: auto;
            .cm-color-picker {
                margin-bottom: 30px;
                margin-top:0;
            }
            .color-picker .color-palette {
                margin: 10px auto;
            }  
            .cm-color-picker .edit-label {
                font-weight: 400;
                color: #C0C2C4;
            }     
            .cm-style-upload-holder {
                border: 1px dashed #C0C2C4;
                padding: 30px 0 36px;    
                min-height: 157px;      
                max-height: 157px;    
                display: flex;
                align-items: center;
                flex-wrap: wrap;   
                .cm-style-container {
                    max-width: 200px;
                    margin: 0 auto;
                }
                .cm-brand {
                    margin: 0 auto;
                }
                .cc-csv-btn .btn {
                    border-radius: 4px;
                }
            }     
        }
        .style-modal-footer {
            .btn {
                min-width: 268px;
                min-height: 44px;
            }
        }
    }
}
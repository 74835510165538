// :root {
//     --gray-text: #6C757D;
// }

$font-path:"../font";
$dark-bg:#141515;
$dark-grey-bg:#1f2021;
$dark-grey-font:#c0c2c3;
$theme-blue:#468be8;
$theme-red:#ff4343;
$white-font:#fff;
$black-font:#222;
$panel-grey-txt:#7b7b7b;
$light-bg: #fff;
$slate-grey:#2e2e2e;
$light-gray-text: #6C757D;
@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-Black.woff2') format('woff2'),
        url('../../font/Inter-Black.woff') format('woff');
    // src: url('../font/Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-Light.woff2') format('woff2'),
        url('../../font/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-Bold.woff2') format('woff2'),
        url('../../font/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-Medium.woff2') format('woff2'),
        url('../../font/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-ExtraBold.woff2') format('woff2'),
        url('../../font/Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-ExtraLight.woff2') format('woff2'),
        url('../../font/Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-SemiBold.woff2') format('woff2'),
        url('../../font/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-Thin.woff2') format('woff2'),
        url('../../font/Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../../font/Inter-Regular.woff2') format('woff2'),
        url('../../font/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}







    body{
        font-family: Inter !important;
        
    }
    // * {
    //     font-family: Inter !important;
    // }
    
    .fs-9{font-size: 9px !important;}
    .fs-10{font-size: 10px !important;}
    .fs-11{font-size: 11px !important;}
    .fs-12{font-size: 12px !important;}
    .fs-13{font-size: 13px !important;}
    .fs-14{font-size: 14px !important;}
    .fs-15{font-size: 15px !important;}
    .fs-16{font-size: 16px !important;}
    .fs-17{font-size: 17px !important;}
    .fs-18{font-size: 18px !important;}
    .fs-19{font-size: 19px !important;}
    .fs-20{font-size: 20px !important;}
    .fs-21{font-size: 21px !important;}
    .fs-22{font-size: 22px !important;}
    .fs-23{font-size: 23px !important;}
    

    .login{
        &-left-part{
            padding:25px 40px;
            width:calc(100% - 35%);
            // border-right: 1px solid #eee;
            @media only screen and (max-width:991px){
                width:50%;
            }
            @media only screen and (max-width:767px){
                width:100%;
                padding:15px;
                min-height: 269px;
                height: 100%;
            }
        }
        &-logo{
            width:160px;
            height: 35px;
        }
    }
    
    .login{
        &-right-part{
            width:35%;
            padding:60px;
            @media only screen and (max-width:991px){
                width:50%;
            }
            @media only screen and (max-width:767px){
                width:100%;
                padding:15px;
            }
        }
        &-infog{
            width:100%;
            height: calc(100% - 35px);
            background: url("../../img/banner.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        &-cap{
            font-size: 28px;
            font-weight: 600;
            line-height: 34px;
            color:#000;
            margin-bottom:9px;
            @media only screen and (max-width:767px){
                font-size: 20px;
            }
        }
        &-para{
            font-size: 14px;
            line-height: 20px;
            color:#8d8d8d;
            font-weight: 300;
            margin-bottom:15px;
            @media only screen and (max-width:767px){
                font-size: 12px;
            }
        }
        &-inp-wrapper{margin-top:23px;}
    }
    .login-wrapper{
        @media only screen and (max-width:767px){
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .custom-input-holder{
        min-height: 90px;
        margin-bottom: 15px;
        position: relative;
        .custom-label{
            font-size:14px;
            line-height: 20px;
            font-weight: 400;
            color:$dark-grey-font;
            margin-bottom:10px;
            @media (max-width: 767px){
                font-size: 13px !important;
            }
        }
        .custom-input{
            @include border-radius(3px);
            width:100%;
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
            color:#333;
            background:#f7f7f7;
            padding:15px 35px 15px 45px;
            border:1px solid transparent;
            &:focus{
                border:1px solid $theme-blue;
            }
            @media (max-width: 767px){
                font-size: 12px !important;
            }
        }
        .input-error-msg{
            font-size: 13px;
            font-weight: 300;
            color:$theme-red;
            padding-top:3px;
            position: absolute;
        }
    }
    .login-btn{
        display: block;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        padding:15px;
        box-shadow: 0px 4px 0px rgba(70, 139, 232, 0.24), 0px 0px 45px 4px rgba(70, 139, 232, 0.3);
    }
    
    .user-avatar-inpt{
        @include position(absolute,50%,17px,auto,auto);
        transform: translateY(-50%);
        svg{
            width:20px;
            height: auto;
            fill: #c0c0c0;
        }
    }
    .pass-lock{
        @include position(absolute,50%,15px,auto,auto);
        transform: translateY(-50%);
        svg{
            width:20px;
            height: auto;
            fill: #c0c0c0;
        }
    }
    .pass-eye{
        cursor: pointer;
        @include position(absolute,18px,auto,9px,auto);
        svg{
            width:20px;
            height: auto;
            fill: #8d8d8d;
        }
    }
    .login-arrow{
        display: inline-block;
        animation: login-arrow 2s ease infinite;
        svg{
            width:16px;
            height: auto;
            fill:$white-font;
            margin-top: -2px;
        }
    }
    @keyframes login-arrow{
        0%{transform: translateX(0px);}
        50%{transform: translateX(8px);}
        100%{transform: translateX(0px);}
      }
    
    
    .cc-blk{
        min-height: calc(100% - 56px);
    }
    html,body,#root,.theme,.main-wrapper{
        height: 100%;
        scrollbar-color: #999#f8f8f8;
        scrollbar-width: thin;
    }
    .cc{
        &-heading{
            font-size: 40px;
            font-weight: 500;
            color:$black-font;
            line-height: 48px;
            margin:16px 0;
            text-transform: capitalize;
            text-align: center;
            @media screen and (max-width:767px){
                font-size: 20px;
                margin:12px 0;
            }
        }
        &-para{
            font-size: 14px;
            font-weight: 400;
            color:$panel-grey-txt;
            line-height: 20px;
            width:60%;
            margin:0 auto;
            text-align: center;
            @media screen and (max-width:767px){
                font-size: 12px;
                width:100%;
                padding:0 15px;
            }
        }
        &-left-part{
            margin-top:10%;
            padding:4% 10% 0;
            // border-right: 1px solid $dark-grey-font;
            @media (max-width: 991px){
                margin-top:0;
                border-right: 0;
                border-bottom: 1px solid $dark-grey-font;
                padding: 4% 10% 5%;
            }
            @media (max-width: 767px){
                padding:15px;
            }
        }
        
        &-right-part{
            margin-top:10%;
            padding:4% 10% 0;
            @media (max-width: 991px){
                padding:15px;
            }
        }
        &-csv-blk{
            @include border-radius(4px);
            background-color: #f7f7f7;
            padding:8px;
            color:$dark-grey-font;
        }
        &-csv-btn{
            .csv-upload{
                font-size: 16px;
                font-weight: 600;
                line-height: 19px;
                text-transform: uppercase;
                height: 54px;
                width: 160px;
                @media (max-width: 767px){
                    font-size: 12px;
                    height: auto;
                    width:auto;
                }
            }
        }
    }
    .cc-wrapper .custom-input-holder .custom-label{
        font-weight: 600;
        color:$panel-grey-txt;
    }
    .cc-wrapper .custom-input-holder .custom-input{
        font-size: 16px;
        font-weight: 400;
        padding:24px 35px;
        @media (max-width: 767px){
            padding:15px;
        }
    }
    .download-csv{
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color:$panel-grey-txt;
        line-height: 20px;
        text-decoration: underline;
        margin-top:6px;
        cursor: pointer;
    }
    .theme-bluebtn{
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        min-width: 98px;
        height: 57px;
        &:hover{opacity: .85;}
        @media (max-width: 767px){
            font-size: 13px !important;
            min-width: auto;
            height: auto;
        }
    }
    .cc-or-seperator{
        @include transform(0%,-50%);
        @include position(absolute,47%,auto,-40px,auto);
        width: 80px;
        height: 80px;
        background-color:#f7f7f7;
        border-radius: 50%;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        text-transform: uppercase;
        color: $black-font;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 991px){
            @include transform(-50%,0%);
            @include position(absolute,85%,50%,auto,auto);
        }
        @media (max-width: 767px){
            top:78%;
        }
    }
    

.cp-img-holder{
    width:180px;
    height: 125px;
    margin: 0 auto 30px;
    background: url("../../img/course_create.png");
    background-repeat: no-repeat;
    background-size: 100%;
}
.cp-heading{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color:$black-font;
}
.cp-subheading{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color:$panel-grey-txt;
}
.theme-btnblue{
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding:12px 23px;
    &:hover{opacity: .85;}
}
.cm-btn{
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    min-width:163px;
    min-height: 40px;
    box-shadow: none;
    &:hover{opacity: .85;}
}
.b-logo-up{
    @include border-radius(0);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    min-height: 40px;
    text-transform: uppercase;
    box-shadow: none;
    &:hover{opacity: .85;}
}
// custom checkbox
.custom-checkbox {
    .check-label{
        font-size: 14px;
        font-weight: 400;
        color:$panel-grey-txt;
        line-height: 20px;
        padding-left: 15px;
    }
	.checkmark {
        @include border-radius(4px);
		position: absolute;
		top: 0;
		left: 0;
		height: 17px;
		width: 17px;
		border: 1px solid #ccc;
        margin-top:2px;
		&:after {
			display: none;
			content: "";
			position: absolute;
			left: 4px;
			top: 2px;
			width: 6px;
			height: 9px;
			border: solid $theme-blue;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		padding: 0;
		margin: 0;
		margin-right: 5px;
		vertical-align: middle;
		&:checked {
			~ {
				.checkmark {
					&:after {
						display: block;
					}
				}
			}
		}
	}
	display: block;
	position: relative;
	padding-left: 15px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.cancel-btn{
    font-size: 13px;
    font-weight: 500;
    color:$panel-grey-txt;
    line-height: 20px;
    box-shadow: none;
    background:none;
    outline:none;
    border:none;
    &:hover{opacity: .85;}
}


.pb-wrapper{
    padding:3% 4%;
    min-height: calc(100% - 56px);
    .pb-card{
        position: relative;
        @include border-radius(6px);
        background-color:#f0efef;
        height: 125px;
        padding:25px;
        overflow: hidden;
        .pb-count{
            font-size: 72px;
            line-height: 87px;
            font-weight: 700;
            text-shadow: 0px 4px 4px rgba(176, 176, 176, 0.15);
        }
        .pb-desc{
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color:$light-gray-text;
        }
        .tick{
            @include position(absolute,auto,auto,21px,21px);
            svg{
                width:27px;
                height: auto;
                fill:$theme-blue;
            }
        }
        @media (max-width: 991px){
            height: auto;
            padding:15px;
            margin-bottom: 10px;
        }
    }
    .table-wraper{
        overflow-x: auto;
        scrollbar-color: #999 #f8f8f8;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            height: 5px;
            height: 4px; }
          
        &::-webkit-scrollbar-track {
            background: #f8f8f8;
            border-radius: 10px;
         }
          
        &::-webkit-scrollbar-thumb {
            background: #999;
            border-radius: 10px;
            cursor: pointer; 
        }
        .dropdown-menu {
            min-width: 100px;
            a {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                padding: 4px 12px;
                color: $slate-grey;
                svg {
                    width: 12px;
                    fill: $slate-grey;
                }
                &:hover {
                    background: none;
                    color: $slate-grey;
                }
            }
        }
    }
}
.pointer {
    cursor: pointer;
}
.col:nth-child(1) .pb-card .pb-count{color:$theme-blue;}
.col:nth-child(2) .pb-card .pb-count{color:#8791DD;}
.col:nth-child(3) .pb-card .pb-count{color:#B364C8;}

.custom-progress{
    height: 4px;
    @include position(absolute,auto,4px,4px,0);
    .progress-bar{background: linear-gradient(90deg, #E5E7EB 0%, #E5E7EB 35.94%, #56C4F5 68.75%, #C156C2 100%);}
}



//   08-11
.main-wrapper {
    .content-inner {
        height: calc(100vh - 56px);
        main {
            flex: 1;
        }
    }
}

// top-bar
.top-bar {
    background: #fff;
    min-height: 56px;
    align-items: center;
    @media screen and (max-width: 991px) {
        padding: 0 15px;
    }
    .create-btn {
        padding: 13px 20px;
        font-size: 12px;
        line-height: 28px;
        color:$light-gray-text;
        font-weight: 600;
        margin-bottom:0;
        outline: none;
        flex: 0 0 227px;
        max-width: 227px;
        @include border-radius(0);
        box-shadow: 0 0 8px 2px rgba(240,239,239,0.75);
        -webkit-box-shadow: 0 0 8px 2px rgba(240,239,239,0.75);
        -moz-box-shadow: 0 0 8px 2px rgba(240,239,239,0.75);
        @media screen and (max-width: 991px) {
            flex: 0 0 185px;
            max-width: 185px;      
            padding: 13px 0px;  
        }
        svg {
            stroke: $light-gray-text;
            // margin-right: 5px;
        }
    }
    .topbar-menu {
        flex: 1;
        align-items: center;
        padding: 0 36px;
        @media screen and (max-width: 991px) {   
            padding: 0px 0 0 15px;
        }
        // h3 {
        //     font-weight: 600;
        //     font-size: 16px;
        //     line-height: 19px;
        //     color: $light-gray-text;
        //     margin-bottom: 0;
        //     flex: 1;
        //     position: relative;
        //     &::before {
        //         content: '';
        //         height: 1px;
        //         width: 20%;
        //         background: #CBC6C6;
        //         position: absolute;
        //         bottom: -8px;
        //         opacity: 0.5;
        //     }
        // }
        input {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: $light-gray-text;
            margin-bottom: 0;
            flex: 1;
            max-width: 160px;
            position: relative;
            border: none;
            border-bottom: 1px solid rgba(#CBC6C6, 0.5);
            padding-bottom: 8px;
            background: transparent;
        }
        ul {
            align-items: center;
            li {
                .notification {
                    position: relative;
                    svg {
                        stroke: $light-gray-text;
                    }
                    span {
                        background: $theme-blue;
                        box-shadow: 0px 1px 4px rgba(70, 139, 232, 0.39);
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        left: 12px;
                        top: 3px;
                    }
                }
                .account-user-avatar {
                    display: inline-block;
                    width: 33px;
                    height: 33px;
                    @include border-radius(8px);
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .dropdown {
                    .dropdown-toggle {
                        &::after {
                            content: none;
                        }
                    }
                    .dropdown-menu {
                        -webkit-animation-name: fadeInDown;
                        animation-name: fadeInDown;
                        -webkit-animation-duration: 0.5s;
                        animation-duration: 0.5s;
                        -webkit-animation-fill-mode: both;
                        animation-fill-mode: both;
                        top: 100% !important;
                        left: -240px !important;
                        margin-top: 10px !important;
                        @include border-radius(8px);
                        border: none;
                        // border: 1px solid red;
                        // padding: 20px 0;
                        box-shadow: none;
                        background: $light-bg;
                        min-width: 297px;
                        padding-bottom: 0;
                        &::before {        
                            content: '';
                            border-bottom: 8px solid $light-bg;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            transform: rotate(360deg);
                            width: 21px;
                            height: 6px;
                            position: absolute;
                            top: -6px;
                            right: 22px;
             
                        }
                        .dropdown-header {
                            padding: 20px 30px 0 30px;
                            margin-bottom: 16px;
                            h6 {
                                font-size: 16px;
                                line-height: 22px;
                                font-weight: 400;    
                                color: $light-gray-text;                                
                            }
                            p {
                                font-size: 10px;
                                line-height: 18px;
                                font-weight: 400;  
                                margin-bottom: 0;
                                color: $light-gray-text; 
                            }
                        }
                        ul {
                            padding: 0 30px;
                            margin-bottom: 30px;
                            li {
                                a {
                                    padding: 10px 0;
                                    .dropdown-icon {
                                        margin-right: 18px;
                                        &.stroke {
                                            svg {
                                                stroke: $light-gray-text;
                                            }
                                        }
                                        &.fill {
                                            svg {
                                                fill: $light-gray-text;
                                            }
                                        }
                                    }
                                    &:hover {
                                        background: none;
                                    }
                                    span {
                                        font-size: 14px;
                                        line-height: 16px;
                                        font-weight: 400; 
                                        color: $light-gray-text;
                                    }
                                }
                            }
                        }
                        .mode-change  {
                            padding: 20px 30px;
                            h6 {
                                font-size: 14px;
                                line-height: 20px;
                                font-weight: 400; 
                                flex: 1;
                                color: $light-gray-text;
                            }
                            
                            .toggle-switch {
                                position: relative;
                                width: 30px;
                                    
                                label {
                                    position: absolute;
                                    width: 100%;
                                    height: 14px;
                                    background-color: #28292c;
                                    border-radius: 50px;
                                    cursor: pointer;
                                }
                                
                                input {
                                    position: absolute;
                                    display: none;
                                }
                                
                                .slider {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50px;
                                    transition: 0.3s;
                                }
                                
                                input:checked ~ .slider {
                                    background-color: #d8dbe0;
                                }
                                
                                .slider::before {
                                    content: "";
                                    position: absolute;
                                    top: 2px;
                                    left: 3px;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    transition: 0.3s;
                                }
                                
                                input:checked ~ .slider::before {
                                    transform: translateX(8px);
                                    box-shadow: 5px 0 0 0px #3c3e41;
                                    border-radius: 100%;
                                    background: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// aside
aside {
    flex: 0 0 227px;
    max-width: 227px;
    background: $light-bg;
    box-shadow: 3px  0px 20px 8px rgba(240,239,239,0.75);
    -webkit-box-shadow: 3px  0px 20px 8px rgba(240,239,239,0.75);
    -moz-box-shadow: 3px  0px 20px 8px rgba(240,239,239,0.75);
    @media screen and (max-width: 991px) {
        width: 230px;
        max-width: 230px;
        height: 100%;
        top: 56px;
        left: -400px;
        overflow:hidden;
        // background-color: black;
        box-shadow: 5px 5px 10px grey;
        position: fixed;
        z-index: 11;
        opacity: 0;
        transition: all 0.25s;
        -webkit-transition: all 0.25s;
    }
    &.is-visible {
        opacity: 1;
        left: 0;
    }
    .tab-content {
        max-height: calc(100vh - 56px);
        overflow: auto;
        margin-right: 1px;
        .accordion>.card {
            border-bottom: 1px solid #dddada;
            border: none;
            @include border-radius(0);
            .card-header {
                @include border-radius(0);
                margin-bottom: 0;
                background: #f0efef;
                padding: 0 20px;
                // border: none;
                border-bottom: 1px solid #dddada;
                &:first-child {
                    border-bottom: 1px solid #dddada;
                }
                h5 {
                    a {
                        width: 100%;
                        display: block;
                        text-align: left;
                        outline: none;
                        box-shadow: none;
                        min-height: 40px;
                        font-size: 16px;
                        line-height: 26px;
                        color:$light-gray-text;
                        font-weight: 600;
                        text-decoration: none;
                        position: relative;
                    }
                }
            }
            .card-body {
                padding: 0;
                .card {
                    background: transparent !important;
                    padding: 0 20px 0 40px;
                    border: none !important;
                    border-bottom: 1px solid #dddada !important;
                    @include border-radius(0);
                    &:last-child {
                        border: none !important;
                    }
                    .card-header {
                        border: none;
                        background: transparent !important;
                        padding: 0;
                        a {
                            width: 100%;
                            display: block;
                            text-align: left;
                            outline: none;
                            box-shadow: none;
                            min-height: 30px;
                            font-size: 14px;
                            line-height: 20px;
                            color:$light-gray-text;
                            font-weight: 600;
                            text-decoration: none;
                            position: relative;
                            padding: 8px 0 8px 20px;
                            &:after {
                                content: "";
                                position: absolute;
                                left: 0px;
                                z-index: 11;
                                display: block;
                                width: 8px;
                                height: 8px;
                                border-top: 8px solid #c4c4c4;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                transform: rotate(275deg);
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                            &[aria-expanded="true"]:after {
                                transform: rotate(360deg);
                            }
                            strong {
                                font-size: 20px;
                                line-height: 27px;
                                font-weight: 700;
                                color:rgba($light-gray-text, 0.5);
                                margin-right: 7px;
                            }
                        }
                    }
                }
            }
        }                
        .card-header {
            a {
                position: relative;
                padding-left: 20px;
                &:after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    z-index: 11;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-top: 8px solid #c4c4c4;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    transform: rotate(360deg);
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                &[aria-expanded="true"]:after {
                    transform: rotate(180deg);
                }
            }
        }
        .slide-wrap {
            // margin-left: 7px;
            .slide-inner {                        
                max-height: 300px;
                    overflow: auto;
                li {
                    padding-left: 18px;
                    padding-bottom: 15px;
                    margin-left: 8px;
                    position: relative;
                    min-height: 80px;
                    // border
                    &:first-child {
                        &::before {
                            content: none;
                        }
                    }
                    &::before {
                        content: '';
                        border-left: 1px dashed #cbc6c6;
                        position: absolute;
                        width: 5px;
                        height: 100%;
                        left: -1px;
                        top: -80px;
                        -webkit-transition: .5s ease-in-out;
                        -moz-transition: .5s ease-in-out;
                        -o-transition: .5s ease-in-out;
                        transition: .5s ease-in-out;
                    }
                    .slide-list {
                        &::before {
                            content: '';
                            width: 14px;
                            height: 14px;
                            background: #fff;
                            border: 1px solid #cbc6c6;
                            position: absolute;
                            left: -8px;
                            border-radius: 50px;
                            z-index: 9;   
                            

                        }
                        &::after {
                            content: '';
                            width: 10px;
                            height: 10px;
                            background: $theme-blue;
                            border: 2px solid #fff;
                            position: absolute;
                            left: -6px;
                            top: 2px;
                            border-radius: 50px;
                            z-index: 9;
                            opacity: 0;
                            animation-duration: 1s;
                            animation-fill-mode: both;
                            -webkit-animation-duration: 1s;
                            -webkit-animation-fill-mode: both

                        }
                    }
                    .img-wrap {
                        position: relative;
                        filter: drop-shadow(0px 4px 4px rgba(70, 139, 232, 0.15));
                        border-radius: 6px;
                        overflow: hidden;
                        max-height: 74px;
                        opacity: 0.5;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        h3 {
                            font-weight: 800;
                            font-style: italic;
                            font-size: 43px;
                            line-height: 52px;
                            opacity: 0.63;
                            color: $light-bg;
                            @include position(absolute,auto,auto,10px,0);
                        }

                    }

                    &.active {
                        &:first-child {
                            &::before {
                                content: none;
                            }
                        }
                        &::before {
                            border-color:  $theme-blue;
                        }
                        .img-wrap {
                            opacity: 1;
                        }
                        .slide-list {
                            &::before {
                                background: $theme-blue;
                                left: -8px;

                            }
                            &::after {
                                opacity: 1;
                                animation-name: fadeInDown ;
                                -webkit-animation-name: fadeInDown ;
                            }
                        }
                    }
                }
            }
        }
    }
}

// video-sec
.video-sec {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: auto;
    max-height: calc(100vh - 56px);
    background: #f9f9f9;
    padding: 24px 36px;
    position: relative;
    @media screen and (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;      
        padding: 24px 20px;
    }
    img {
        width: 100%;
    }
    .preview-sec {
        padding-bottom: 80px;
        .btn {
            min-height: 34px;
            min-width: 96px;
            @include border-radius(2px);
        }
    }
    .video-inner {
        .video-head {
            margin-bottom: 14px;
            h4 {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: $light-gray-text;
                border-bottom: 0.5px solid #CBC6C6;
                display: inline-block;
            }
        }
    }
}
.video-control {                    
    .rSlider {
        position: relative;
        margin: 16px 0 10px;
        .slide {
            background: $theme-blue;
            height: 5px;
            width: 80px;
            position: absolute;
            left: 0;
            top: 9px;
            z-index: 2;
            pointer-events: none;
          }
          input[type=range] {
            position: relative;
            -webkit-appearance: none;
            width: 100%;
            height: 5px;
            background: #dfdfdf;
            outline: none;
            -webkit-transition: .2s;
            transition: opacity .2s;
          }
          
        input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 12px;
            height: 12px;
            background: $theme-blue;
            cursor: pointer;
            border-radius: 20px;
            z-index: 9;
        }
        input[type="range"]::-moz-range-thumb {
            width: 12px;
            height: 12px;
            background: $theme-blue;
            cursor: pointer;
        }
    }                         
}

.time-duration {
    color: $light-gray-text;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
}  

// .right-sec
.right-sec {
    flex: 0 0 40%;
    max-width: 40%;
    @media screen and (max-width: 991px) {
        max-height: calc(100vh - 56px);
        width: 400px;
        max-width: 400px;
        height: 100%;
        top: 56px;
        right: -400px;
        overflow:hidden;
        background-color: #f0efef;
        box-shadow: 5px 5px 10px grey;
        position: fixed;
        z-index: 11;
        opacity: 0;
        transition: all 0.25s;
        -webkit-transition: all 0.25s;
    }
    &.is-visible {
        opacity: 1;
        right: 0;
    }
    .tab-view {
        display: flex;
        justify-content: center;
        min-height: 100%;
        flex-wrap: wrap;
        .tab-content {
            flex: 1;
            overflow: auto;
            max-height: calc(100vh - 56px);
            overflow-x: hidden;
            .accordion>.card {
                border-bottom: 1px solid #dddada;
                border: none;
                @include border-radius(0);
                .card-header {
                    @include border-radius(0);
                    margin-bottom: 0;
                    background: #f0efef;
                    padding: 0 20px;
                    // border: none;
                    border-bottom: 1px solid #dddada;
                    &:first-child {
                        border-bottom: 1px solid #dddada;
                    }
                    h5 {
                        a {
                            width: 100%;
                            display: block;
                            text-align: left;
                            outline: none;
                            box-shadow: none;
                            min-height: 40px;
                            font-size: 14px;
                            line-height: 20px;
                            color:$light-gray-text;
                            font-weight: 600;
                            text-decoration: none;
                            position: relative;
                        }
                    }
                }
                .card-body {
                    padding: 0;
                    .card {
                        background: transparent !important;
                        padding: 0 20px 0 40px;
                        border: none !important;
                        border-bottom: 0.5px solid rgba(#CBC6C6,0.5) !important;
                        @include border-radius(0);
                        @media screen and (max-width: 1199px) {
                            padding: 0 15px 0 20px;
                        }
                        &:last-child {
                            border: none !important;
                        }
                        .card-header {
                            border: none;
                            background: transparent !important;
                            padding: 0;
                            a {
                                width: 100%;
                                display: block;
                                text-align: left;
                                outline: none;
                                box-shadow: none;
                                min-height: 30px;
                                font-size: 14px;
                                line-height: 20px;
                                color:$light-gray-text;
                                font-weight: 600;
                                text-decoration: none;
                                position: relative;
                                padding: 10px 0 10px 20px;
                                &:after {
                                    content: "";
                                    position: absolute;
                                    left: 0px;
                                    z-index: 11;
                                    display: block;
                                    width: 8px;
                                    height: 8px;
                                    border-top: 10px solid #c4c4c4;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    transform: rotate(275deg);
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                }
                                &[aria-expanded="true"]:after {
                                    transform: rotate(360deg);
                                }
                                strong {
                                    font-size: 20px;
                                    line-height: 27px;
                                    font-weight: 700;
                                    color: #b5babe;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
                .card-inner {
                    padding: 16px 24px;
                    .label-block {
                        flex: 0 0 72px;
                        max-width: 72px;
                        @media screen and (max-width: 1119px) {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                    .field-block {
                        flex: 1;
                        .Regular {
                            width: 65%;
                        }
                        .size {
                            width: 35%; 
                        }
                        .img-upload {
                            position: relative;
                            max-width: 100px;
                            .img-wrap {
                                width: 90px;
                                height: 70px;
                                border: 1px solid #CBC6C6;
                                box-sizing: border-box;
                                border-radius: 2px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .upload-btn {
                                width: 31px;
                                height: 31px;
                                background: #F9F9F9;
                                opacity: 0.75;
                                box-shadow: 0px 4px 4px rgba(176, 176, 176, 0.15);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50px;
                                position: absolute;
                                bottom: -4px;
                                right: -2px;
                                cursor: pointer;
                                input[type=file] {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    opacity: 0;
                                    cursor: pointer;
                                    width: 100%;
                                    height: 100%;
                                }
                                svg {
                                    stroke: $light-gray-text;
                                }
                            }
                        }
                        .editor-list {
                            li {
                                flex: 0 0 25%;
                                border-top: 0.5px solid #CBC6C6;
                                border-bottom: 0.5px solid #CBC6C6;
                                &:first-child {
                                    border-left: 0.5px solid #CBC6C6;
                                    border-radius: 2px 0  0 2px;
                                }
                                &:last-child {
                                    border-right: 0.5px solid #CBC6C6;
                                    border-radius: 0 2px 2px 0;
                                }
                                button {
                                    border-radius: 0;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    textarea.form-control {
                        border: 1px solid #CBC6C6;
                        padding: 15px!important;
                        border-radius: 4px;
                        height: 130px;
                        color: $light-gray-text;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        resize: none;
                        @media screen and (max-width: 1199px) {
                            padding: 8px!important;
                        }
                    }

                    .edit-label {
                        color: $light-gray-text;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 600;
                        margin-bottom: 0;
                        @media screen and (max-width: 1199px) {
                            margin-bottom: 6px;    
                        }
                    }
                    p {
                        // background: #E6F1FF;
                        font-size: 12px;
                        line-height: 18px;
                        font-weight: 400;
                        color: $light-gray-text;
                        margin-bottom: 0;
                        // padding: 8px 24px;
                        display: inline-block;
                        a {
                            margin: 0 10px;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 18px;
                            color: $theme-blue;
                            text-decoration: underline;
                            display: inline-block;
                        }
                    }
                }
            }                
            .card-header {
                a {
                    position: relative;
                    padding-left: 20px;
                    &:after {
                        content: "";
                        position: absolute;
                        left: 0px;
                        z-index: 11;
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-top: 8px solid #c4c4c4;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        transform: rotate(275deg);
                        top: -5px;
                        bottom: 0;
                        margin: auto;
                    }
                    &[aria-expanded="true"]:after {
                        transform: rotate(360deg);
                    }
                }
            }
        }
        .tab-head {
            flex: 0 0 104px;
            max-width: 104px;
            background: #413E4C;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-y: auto;
            max-height: calc(100vh - 56px);
            position: relative;
            overflow-x: hidden;
            &::before {
                content: '';
                background: url('../../img/right-bar-bg.png') no-repeat;
                height: 110px;
                width: 104px;
                position: fixed;
                bottom: 0;
                z-index: 0;
                right: 0;
                background-size: cover;
            }
            ul {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // flex-direction: column;
                z-index: 1;
                position: relative;
                padding: 24px 10px; 
                li {
                    text-align: center;
                    margin-bottom: 13px;
                    a {
                        display: block;
                        span {
                            background: linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.08) 100%);
                            box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.03);
                            backdrop-filter: blur(4px);
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 50px;
                            height: 50px;
                            margin: 0 auto;
                            margin-bottom: 10px;
                            img {

                            }
                        }
                        small {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 600;
                            color: rgba($light-bg,0.35);                            
                        }
                    }
                    &.active {
                        a {
                            display: block;
                            span {
                                background: $theme-blue;
                                box-shadow: 0px 4px 0px rgba(70, 139, 232, 0.24), 0px 0px 45px 4px rgba(70, 139, 232, 0.3);
                            }
                            small {
                                color: #fff;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-80px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }

 video::-webkit-media-controls-volume-slider {  display: none; }

 video::-webkit-media-controls-mute-button {  display: none; }

 .divider {
    padding: 0;
    height: 1px;
    background: #e5e2e2;
    margin: 14px 0;
 }


 .fill-i {
    fill: $light-gray-text;
 }
 .outline-i {
    stroke: $light-gray-text;
 }
 .color-picker {
    .color-palette {
        width: 26px;
        height: 26px;
        display: block;
        background: #000;
        border-radius: 50px;
        position: relative;
        margin-bottom: 10px;
        i {
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -4px;
            right: -4px;
            cursor: pointer;
        }
    }
}

.save-apply {
    position: absolute;
    top: 72px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    p {
        background: #E6F1FF;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #393B3D;
        margin-bottom: 0;
        padding: 8px 24px;
        display: inline-block;
        a {
            font-weight: 600;
            font-size: 14px;
            line-height: 28px;
            color: $theme-blue;
            text-decoration: underline;
            display: inline-block;
        }
    }
}

.custom-height {
    max-height: 240px;
    overflow-y: auto;
}

.grid-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 10px;
    grid-auto-rows: minmax(70px, auto);
    .grid-list {
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        .icon-wrap {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            background: #5161708f;
            -webkit-transition: all 0.4s ease-in-out 0s;
            -moz-transition: all 0.4s ease-in-out 0s;
            transition: all 0.4s ease-in-out 0s;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                margin-right: 0;
                cursor: pointer;
                &.img-view {
                    background: rgba(70, 139, 232, 0.146);
                    opacity: 0.6;
                    box-shadow: inset 5.6px -5.6px 5.6px rgba(53, 106, 176, 0.146), inset -5.6px 5.6px 5.6px rgba(255, 255, 255, 0.146);
                    backdrop-filter: blur(5.04px);
                    &:hover {
                        background: rgba(0, 0, 0, 0.6);
                        box-shadow: none;
                    }
                }
                &.img-edit {
                    background: rgba(70, 139, 232, 0.146);
                    opacity: 0.6;
                    &:hover {
                        background: rgba(0, 0, 0, 0.6);
                        box-shadow: none;
                    }
                }
                svg {
                    fill: #fff;
                }
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:hover {
            .icon-wrap {
                opacity: 1;
            }
        }
    }
}



//  binsu css on 10/11/21
.theme .panel-full-header{
    box-shadow: 0 0 8px 2px rgba(240,239,239,0.75);
    -webkit-box-shadow: 0 0 8px 2px rgba(240,239,239,0.75);
    -moz-box-shadow: 0 0 8px 2px rgba(240,239,239,0.75);
}
.theme .panel-full-header .create-btn{box-shadow: none;}
.cm-wrapper{
    // min-height: calc(100% - 56px);
    // padding:3% 6% 1% 4%;
    padding: 40px 62px 40px 60px;
    height: calc(100vh - 56px);
}
.cm-header{
    .cm-caption{
        font-size: 28px;
        line-height: 34px;
        font-weight: 600;
        color:$black-font;
        @media (max-width: 767px){
            font-size: 15px;
        }
    }
    .cm-subhead{
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color:$panel-grey-txt;
        @media (max-width: 767px){
            font-size: 11px;
        }
    }
}

.cm-blk{min-height: calc(100vh - 200px);}
.cm-left-blk{
    width:16%;
    background-color: #eee;
    .cm-add-mod{
        border-top:1px solid #ccc;
    }
    .cm-mod-wrapper{
        min-height: calc(100vh - 228px);
        max-height: calc(100vh - 228px);
        overflow-y: auto;
        scrollbar-color: #999 #f8f8f8;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            height: 5px;
            width: 5px; }
          
        &::-webkit-scrollbar-track {
            background: #f8f8f8;
            border-radius: 10px;
         }
          
        &::-webkit-scrollbar-thumb {
            background: #999;
            border-radius: 10px;
            cursor: pointer; 
        }
    }
    .cm-mod-container{
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color:#555;
        position: relative;
        text-transform: uppercase;
        border-left:2px solid transparent;
        padding: 16px 5px;
        text-align: center;
        max-width: 100%;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        h3{
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0 auto;
        }
        input{
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color:$light-gray-text;
            background: transparent;
            border: none;
            outline:none;
            border-bottom: 1px solid $dark-grey-font;
            width: 100%;
        }
        &.active{
            border-left:2px solid $theme-blue;
            background-color: #ddd;
            &:hover {
                .cm-del-icon {
                    display: block;
                }
            }
        }
    }
}
.cm-mod-container .cm-del-icon{
    @include position(absolute,47%,auto,8px,auto);
    transform: translateY(-50%);
    margin-top: 0px;
    display: none;
    svg {
        fill: $light-gray-text;
        // fill: red;
    }
}
.cm-right-blk{
    width:84%;
    background-color: #f9f9f9;
}
.cm-add-label{
    padding:8px 15px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color:$panel-grey-txt;
    text-transform: uppercase;
    cursor: pointer;
    .plus{
        // font-size: 27px;
        // font-weight: 100;
        // line-height: 20px;
        // margin-top: -4px;
         padding-right: 6px;
    }
    svg {
        fill: $light-gray-text;
        // fill: red;
    }
}
.cm-slide-blk{
    height: 52px;
    background-color: #f5f5f5;
    border-bottom:3px solid #ddd;
    .cm-slide-wrapper{
        height: 52px;
        max-width: 100%;
        overflow-x: auto;
        overflow-y:hidden;
        scrollbar-color: #999 #f8f8f8;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            height: 5px;
            height: 4px; }
          
        &::-webkit-scrollbar-track {
            background: #f8f8f8;
            border-radius: 10px;
         }
          
        &::-webkit-scrollbar-thumb {
            background: #999;
            border-radius: 10px;
            cursor: pointer; 
        }
        .cm-slide-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:16px;
            border-bottom:3px solid transparent;
            cursor: pointer;
            font-weight: 600;
            &.active{
                color: #333;
                border-color: $theme-blue;
                &:hover {
                    .cm-del-icon {
                        display: block;
                    }
                }
            }
            
        }

    }
}
.cm-screen-blk{
    border-bottom:1px solid #eee;
    .cm-screen-wrapper{
        overflow-x: auto;
        scrollbar-color: #999 #f8f8f8;
        scrollbar-width: thin;
        .cm-screen-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:16px 10px;
            cursor: pointer;
            font-weight: 400;
            border-bottom: 3px solid transparent;
            &.active{
                color: #333;
                border-bottom: 3px solid #468be8;
                &:hover {
                    .cm-del-icon {
                        display: block;
                    }
                }
            }
        }
        &::-webkit-scrollbar {
            height: 5px;
            height: 4px; }
          
        &::-webkit-scrollbar-track {
            background: #f8f8f8;
            border-radius: 10px;
         }
          
        &::-webkit-scrollbar-thumb {
            background: #999;
            border-radius: 10px;
            cursor: pointer; 
        }
    }
}
.cm-gen-spec{ 
    min-width: 115px;
    width:115px;
    position: relative;
    p{
        max-width: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    input{
        font-size: 14px;
        font-weight: 600;
        color:$dark-grey-font;
        line-height: 20px;
        background: transparent;
        border: none;
        outline:none;
        border-bottom: 1px solid $dark-grey-font;
        width: 100%;
    }
}
.panel-label{
    font-size: 14px;
    font-weight: 400;
    color: $panel-grey-txt;
    line-height: 20px;
    max-width: 100%;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cm-audscript-blk{
    padding:16px;
}
.panel-txtarea-blk{
    .panel-txtarea{
        @include border-radius(5px);
        border:none;
        width:100%;
        height: 80px;
        max-height: 80px;
        background-color: #f3f3f3;
        font-size: 14px;
        font-weight: 300;
        color:rgba($black-font,.8);
        line-height: 20px;
        padding:15px !important;
        resize:none;
        border:1px solid transparent;
        scrollbar-color: #999 #f8f8f8;
        scrollbar-width: thin;
        &::placeholder{
            color:rgba($panel-grey-txt,.5);
        }
        &::-webkit-scrollbar {
            height: 5px;
            width: 5px; }
          
        &::-webkit-scrollbar-track {
            background: #f8f8f8;
            border-radius: 10px;
         }
          
        &::-webkit-scrollbar-thumb {
            background: #999;
            border-radius: 10px;
            cursor: pointer; 
        }
        &:focus {
            border:1px solid $theme-blue;
        }
    }
    .panel-count{
        font-size: 14px;
        font-weight: 300;
        color:rgba($black-font,.8);
        line-height: 20px;
    }    
}

.cm-ost-blk{
    padding:16px;
    .panel-txtarea{
        height: 100px;
        max-height: 100px;
    }
}

.cm-full-lst-blk{
    border-bottom:1px solid #eee;
    .cm-full-lst-wrapper{
        overflow-x:auto;
        overflow-y: hidden;
        scrollbar-color: #999 #f8f8f8;
        scrollbar-width: thin;
        .cm-full-lst-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:10px 0;
            cursor: pointer;
            border-bottom:1px solid transparent;
            position: relative;
            margin-right: 3px;
            padding-right: 10px;
            &.active{
                color: #333;
                border-color: $theme-blue;
                &:hover {
                    .cm-del-icon {
                        display: block;
                    }
                }
            }

        }
        &::-webkit-scrollbar {
            height: 5px;
            height: 4px; }
          
        &::-webkit-scrollbar-track {
            background: #f8f8f8;
            border-radius: 10px;
         }
          
        &::-webkit-scrollbar-thumb {
            background: #999;
            border-radius: 10px;
            cursor: pointer; 
        }
    }
}
.cm-del-icon{
    // @include position(absolute,45%,auto,5px,auto);
    font-size: 27px;
    font-weight: 300;
    line-height: 20px;
    color:$panel-grey-txt;
    margin-top: -2px;
    // transform: translateY(-50%);
    display: none;
    svg {
         fill: $light-gray-text;
    }
}
.cm-full-lst-container.active .cm-del-icon{color: $black-font;}
.cm-vs-blk{
    padding:16px;
    .cm-vs-wrapper{
        background-color: #eee;
        // padding:23px 30px;
        padding: 20px 0 20px 25px;
    }
}

// navtab selector styles
.navtab-selector{
    .nav-tabs {
        border-bottom: none !important;
    }
    .nav-item{margin-right: 20px;}
    .nav-link {
        font-size: 13px;
        font-weight: 500;
        padding: 20px 0;
        text-align: center;
        color: $white-font;
        border: none;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        &.active{
            position: relative;
            background-color: unset;
            border: 0;
            .cm-vs-icon-holder{
                border-color: $theme-blue;
                color:$theme-blue;
            }
            .tick-active{display: block;}
            .cm-asset-name{color:$black-font;}
            .cm-asset-icon svg{stroke:$theme-blue;}
        }
        &:disabled{
            cursor: not-allowed;
        }
    }
}
.cm-vs-icon-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    width:50px;
    height: 50px;
    color:rgba($dark-grey-font,.6);
    @include border-radius(50%);
    border:2px solid rgba($dark-grey-font,.6);
    
}
.cm-asset-icon svg{stroke:rgba($dark-grey-font,.6);}
.cm-asset-name{
    font-size: 14px;
    font-weight: 400;
    color:$panel-grey-txt;
    line-height: 20px;
}
.cm-vs-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .tick-active{
        position: absolute;
        display: none;
    }
}
.asset-txtarea{
    .panel-txtarea{
        max-height: 126px;
        height: 126px;
    }
}
// .cm-illus-blk .cm-full-lst-container{width:125px;}
.cm-a-space{
    width: 130px;
    min-width: 134px;
}
.add-tag-blk{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 126px;
    max-height: 126px;
    overflow-y: auto;
    padding:15px;
    background-color: #f3f3f3;
    @include border-radius(5px);
}
.add-tag-container{
    display: flex;
    justify-content: space-between;
    background-color: $theme-blue;
    color:$white-font;
    padding:0 6px;
    margin-right: 8px;
    margin-bottom:8px;
    scrollbar-color: #999 #f8f8f8;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px; }
      
    &::-webkit-scrollbar-track {
        background: #f8f8f8;
        border-radius: 10px;
     }
      
    &::-webkit-scrollbar-thumb {
        background: #999;
        border-radius: 10px;
        cursor: pointer; 
    }
    .tag-name{
        font-size: 12px;
        line-height: 28px;
        font-weight: 200;
        padding-right: 5px;
    }
    .tag-close{
        font-size: 23px;
        line-height: 25px;
        font-weight: 100;
        cursor: pointer;
    }
}
.add-tag-placeholder{
    font-size: 14px;
    font-weight: 300;
    padding-top: 5px;
    cursor: default;
    color:rgba($panel-grey-txt,.5);
}
.cm-style-blk{
    padding:16px;
    .cm-style-wrapper{
        background-color: #eee;
        padding:23px 33px;
    }
}
.cm-uploaded-logo{
    width:191px;
    height: 108px;
    overflow:hidden;
    @include border-radius(6px);
}
.cm-style-spec{
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    color:$dark-grey-font;
}
.cm-style-upload-holder{
    padding-left:45px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-left: 2px solid rgba($dark-grey-font,.4);
    @media screen and (max-width:991px) {
        padding-left: 25px;
    }
    .cm-brand{
        width:fit-content;
        width:-webkit-fit-content;
        width:-moz-fit-content;
        width:-ms-fit-content;
        &:hover{
            .cm-st-upload-edit{display: block;}
        }
    }
    .cm-brand-close{
        display: flex;
        align-items: center;
        justify-content: center;
        @include position(absolute,-6px,auto,-6px,auto);
        background-color: $theme-blue;
        width:39px;
        height: 39px;
        @include border-radius(50%);
        font-size: 33px;
        font-weight: 100;
        line-height: 23px;
        color:$white-font;
        box-shadow: 0px 0px 10px 1px rgba(70, 139, 232, 0.21);
        cursor: pointer;
        z-index: 99;
    }
}
.cm-style-left-part{
    flex: 0 0 66%;
    max-width: 66%;
}
.cm-style-right-part{
    flex: 0 0 34%;
    max-width: 34%;
}
.cm-st-upload-edit{
    display:none;
    @include position(absolute,0,0,0,0);
    background: rgba(70, 139, 232, 0.806);
    cursor: pointer;
    box-shadow: inset 5.6px -5.6px 5.6px rgba(53, 106, 176, 0.146), inset -5.6px 5.6px 5.6px rgba(255, 255, 255, 0.146);
    .cm-st-edit-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        height:100%;
        
    }
}
.course-scroller{
    max-height: calc(100vh - 296px);
    overflow-y: auto;
    scrollbar-color: #999 #f8f8f8;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px; }
      
    &::-webkit-scrollbar-track {
        background: #f8f8f8;
        border-radius: 10px;
     }
      
    &::-webkit-scrollbar-thumb {
        background: #999;
        border-radius: 10px;
        cursor: pointer; 
    }
}
.cm-st-gallery {
    min-height: calc(100vh - 250px);
}
.cm-gallery-wrapper{
    margin:25px 0;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-auto-flow: row dense;
    grid-gap:15px;
    @media screen and (max-width:991px) {
        grid-template-columns: repeat(3,1fr);
    }
    @media screen and (max-width:767px) {
        grid-template-columns: repeat(2,1fr);
    }
    .cm-gallery-container{
        @include border-radius(6px);
        overflow:hidden;
        cursor: pointer;
        position: relative;
        transition: all .3s ease;
        img{
            height: 100%;
            max-height: 100%;
            object-fit: fill;
        }
        .cm-gallery-menus{display: none;}
        &:hover{
            .cm-gallery-menus{display: block;}
        }
    }
    .cm-gal-menu-left{
        display: flex;
        align-items: center;
        justify-content: center;
        @include position(absolute,0,0,50%,0);
        background: rgba(0, 0, 0, 0.6);
        border-radius: 6px 0px 0px 6px;
    }
    .cm-gal-menu-right{
        display: flex;
        align-items: center;
        justify-content: center;
        @include position(absolute,0,50%,0,0);
        background: rgba($theme-blue,.8);
        border-radius: 0px 6px 6px 0px;
        box-shadow: inset 5.6px -5.6px 5.6px rgba(53, 106, 176, 0.146), inset -5.6px 5.6px 5.6px rgba(255, 255, 255, 0.146);
    }
}
.cm-color-picker{
    margin-top:20px;
    margin-bottom:15px;
    // width: 55%;
    padding-right: 25px;
    .label-block{
        flex:0 0 100px;
        max-width: 100px;
        @media screen and (max-width:1199px) {
            flex:0 0 50px;
            max-width: 50px;
        }
    }
    .field-block{
        flex:1;
        .Regular{width:65%;}
        .size{width:35%;}
    }
    .edit-label{
        color: rgba($panel-grey-txt,.5);
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }
}
.color-picker .color-palette i svg{fill:$panel-grey-txt;}

// 15-11-21
.pb-header{
    min-height: 40px;
    .pb-filter-label{
        font-size: 14px;
        font-weight: 400;
        color:$light-gray-text;
        line-height: 20px;
        white-space: nowrap;
    }

}
.pb-filter-holder{
    .custom-select{
        width:100%;
        height: 40px;
        .form-field{
            height: 100%;
            font-size: 14px;
            font-weight: 400;
        }
        &::after{
            top:50%;
            transform: translateY(-50%);
        }
    }
}
.srch-icon{
    @include position(absolute,50%,15px,auto,auto);
    transform: translateY(-50%);
    svg{
        width:23px;
        height: auto;
        stroke: rgba($black-font,.7);
    }
}
.pb-srch-customiser{
    @include border-radius(2px);
    border:0.5px solid #cbc6c6;
    height: 40px;
    width:fit-content;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:-ms-fit-content;
    // width:58%;
    .srch-holder{
        position: relative;
        width:50%;
        &::after{
            content: "";
            @include position(absolute,5px,auto,0,5px);
            width: 1px;
            background-color: #cbc6c6;
        }
    }
    @media (max-width: 991px){
        width: auto;
        margin-bottom: 10px;
    }
}
.srch-inpt-holder{
    padding-left:43px;
    .srch-input{
        font-size: 14px;
        font-weight: 400;
        color:$panel-grey-txt;
        padding:6px !important;
        border:0;
        padding-right: 32px !important;
        &::placeholder{color:rgba($panel-grey-txt,.7)}
    }
}
.srch-ft-selector{
    width:50%;
    padding:6px;
    .custom-select{
        &::after{
            top:50%;
            transform: translateY(-50%);
        }
    }
    .form-select{
        @include border-radius(0);
        border:0px;
        min-height:38px;
        font-size: 14px;
        font-weight: 400;

    }
}
.pb-tbl-blk{
    margin:6px 0;
    @media screen and (max-width:991px){
        min-width: 1200px;
    }
    .pb-tbl-wrapper{
        @include border-radius(2px);
        margin:8px 0;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 2fr;
        grid-auto-flow: row dense;
        grid-auto-rows: minmax(40px,auto);
        grid-gap:10px;
        background: #F9F9F9;
        border: 0.5px solid rgba(#CBC6C6,.5);
    }
    
    .pb-head-label{
        font-size: 14px;
        font-weight: 600;
        color:$light-gray-text;
        line-height: 20px;
        padding:10px 24px 10px 10px;
        text-transform: capitalize;
    }
    .pb-data-label{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color:$light-gray-text;
        padding:10px 24px 10px 10px;
    }
    .pb-st-count{
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        width: 75px;
        padding-left:15px;
    }
    .pb-progress-bar{
        position: static;
        width:calc(100% - 75px);
        background-color: #cbc6c6;
        height: 5px;
        .progress-bar{
            background: linear-gradient(90deg, #E5E7EB 0%, #E5E7EB 35.94%, #56C4F5 68.75%, #C156C2 100%);
            @include border-radius(100px);
        }
        &.failed{
            .progress-bar{background: $theme-red};
        }
    }
    .pb-data-tbl{
        min-height: 53px;
        .pb-data-label{
            padding:16px 24px 10px 16px;
        }
    }
    .pb-status-indicator{
        width:calc(100% - 100px);
    }
    .pb-status-set{
        padding-left:28px;
    }
    .pb-stat-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width:24px;
        height: 24px;
        svg{
            fill:#000;
            cursor: pointer;
        }
        &.download svg{
            stroke:#000;
        }
    }
    .failed-status{
        font-size: 10px;
        line-height: 18px;
        font-weight: 700;
        color:$theme-red;
        text-transform: uppercase;
        padding-left:3px;
        padding-top:5px;
    }
}

.fs-vdo-blk{
    position: fixed;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    background-color: #fff;
    .fs-vdo-wrapper{
        display:flex;
        align-items: center;
        justify-content: center;
        height:100%;
    }
    .fs-vdo-container{
        width:45%;
        @media (max-width: 991px){
            width:auto;
            padding:0 15px;
        }
    }
}

@media screen and (max-width:575px){
    .col-xs-12{
        flex: 0 0 auto;
        width: 100%;
    }
}
.cp-container{
    @media (max-width: 767px){
        width:100% !important;
    }
} 

// 16/11/21
.srch-temp-blk{
    padding:1% 3%;
    min-height: calc(100% - 56px);
    .srch-temp-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 0.5px solid #cbc6c6;
        height: 40px;
        width:fit-content;
        width:-webkit-fit-content;
        width:-moz-fit-content;
        width:-ms-fit-content;
        margin:0 auto;
    }
    .srch-holder{
        position: relative;
        .srch-input{background-color: transparent;}
    }
    .srch-icon {
        left: 0;
    }
    .srch-inpt-holder {
        padding-left: 35px;
        .srch-input {
            max-width: 200px;
        }
    }
}

// pagination
.pagination-holder{
    margin: 20px;
    li.pagination-nav {
        @include border-radius(3px);
        background: #eee;
        border:1px solid transparent;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 29px;
        font-size: 14px;
        font-weight: 600;
        color: $panel-grey-txt;
        margin-right: 7px;
        cursor: pointer;
        &:hover{
            color: $theme-blue;
        }
        &.active{
            border:1px solid $theme-blue;
            color:$theme-blue;
        }
        &.pagin-prev{
            svg{
                fill:$panel-grey-txt;
            }
        &:hover svg{fill:$theme-blue;}
        &.disabled svg{fill:rgba($dark-grey-font,.6);}
        }
        &.pagin-next{
            svg{
                fill:$panel-grey-txt;
            }
        &:hover svg{fill:$theme-blue;}
        &.disabled svg{fill:rgba($dark-grey-font,.6);}
        }
    }
}
.temp-card{
    img{width:100%;}
    &::before{
    display: none;
    content: '';
    background:$theme-blue;
    @include position(absolute,0,0,0,0);
    z-index: 0;
    opacity: .5;
    }
    &::after{
        display: none;
        content: '';
        background: url(../../img/right-bar-bg.png) no-repeat;
        height: 110px;
        width: 104px;
        position: absolute;
        bottom: 0;
        z-index: 0;
        right: 0;
        background-size: cover;
        opacity: .2;
    }
}
.temp-card:hover::before{display: block;}
.temp-card:hover::after{display: block;}
.temp-part{
    display: flex;
    @media (max-width: 991px){
        flex-direction: column;
    }
}
.temp-left-part{
    flex:0 0 63%;
    max-width: 63%;
    .vdo-speaker svg{fill:$dark-grey-font;}
    .play-btn svg{
        fill:$dark-grey-font;
        margin-top: -5px;
    }
    @media (max-width: 991px){
        flex:0 0 100%;
        max-width: 100%;
    }
}
.temp-right-part{
    flex:0 0 37%;
    max-width: 37%;
    .temp-cap{
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        margin: 4px 0px 10px 0px;
        color: $black-font;
        text-transform: uppercase;
    }
    .temp-subcap{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color:$panel-grey-txt;
        padding-right: 5px;
    }
    .rSlider{margin: 12px 0 10px;}
    @media (max-width: 991px){
        flex:0 0 100%;
        max-width: 100%;
        padding-left:0px !important;
        margin-top:12px;
    }
}
.dev-spec-wrapper{
    margin-bottom:8px;
    width:88%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-auto-flow: row dense;
    grid-auto-rows: (minmax(100px,auto));
    .dev-spec-container{
        &.active{
            .dev-spec-label{
                color:$black-font;
            }
            &:nth-child(1) .dev-spec-icon{background: url(../../img/Safari-active.png);}
            &:nth-child(2) .dev-spec-icon{background: url(../../img/Macbook-active.png);}
            &:nth-child(3) .dev-spec-icon{background: url(../../img/Pixel-active.png);}
        }
        &:nth-child(1) .dev-spec-icon{background: url(../../img/Safari.png);}
        &:nth-child(2) .dev-spec-icon{background: url(../../img/Macbook.png);}
        &:nth-child(3) .dev-spec-icon{background: url(../../img/Pixel.png);}
    }
    .dev-spec-icon{
        width:60px;
        height: 60px;
        margin:0 auto;
        background-position: center !important;
        background-repeat: no-repeat !important;
    }
    .dev-spec-label{
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color:$panel-grey-txt;
        text-align: center;
    }
}

.back-btn {
    position: absolute;
    left: 30px;
    top: 70px;
    svg {
        fill: $slate-grey;
    }
}

.loader {
    position: absolute;
    /* background: #00000078; */
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 47%);
}


.srch-input::-webkit-search-cancel-button{   
    cursor: pointer;
}
.search-close {
    position: absolute;
    right: 6px;
    cursor: pointer;
    top: 7px;
    background: none;
    box-shadow: none;
    border: none;
}
.search-close svg {
    fill: #468be8;
    width: 16px;
}

.srch-input:not(:valid) ~ .search-close {
	display: none;
}
.pro-temp-container {
    padding-left: 5px;
    .MuiSvgIcon-root {
        fill: #468be8;
    }
    .cm-header {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color:#b6c2c3;
        text-align: center;
    }
}

.custom-number input {
    background: #FFFFFF;
    border: 0.5px solid #cbc6c6;
    color: #6C757D;
    border: none;
    min-height: 26px;
}

// 23/12/21
.inp-txt{
    color:$light-gray-text;
    padding:4px 8px !important;
    &::placeholder{color:$light-gray-text;}
}

.nw-color-pick{
    .custom-color-picker{
        -webkit-appearance: none;
        padding: 0;
        border: none;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        background: none;
      }
      .custom-color-picker::-webkit-color-swatch {
        border: none;
        border-radius: 50%;
        padding: 0;
      }
      .custom-color-picker::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 50%;
        padding: 0;
      }
      .eye-drop{pointer-events: none;}
}

.tab-assets{
    border-bottom:1px solid #eee;
    .nav-tabs{
        width: fit-content;
        width: -moz-fit-content;
        width: -ms-fit-content;
        width: -webkit-fit-content;
        margin:auto;
    }
    .nav-link{
        &.active{border-bottom:3px solid $theme-blue;}
    }
}
.aspt-upload-holder{
    border: 1px dashed #C0C2C4;
    padding: 30px 0 36px;
    width:390px;
    max-width: 390px;
    min-height: 157px;
    max-height: 157px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin:0 auto;
    .aspt-container{
        max-width: 200px;
        margin: 0 auto;
    }
    .cc-csv-btn .btn{border-radius: 4px;}
}
.aspt-blk{
    padding:30px 0;
}
.tab-bg-switching{min-height: calc(100vh - 56px);}
.inner-tab-assets{
    border-bottom:1px solid #eee;
    .nav-link{
        &.active{border-bottom:3px solid $theme-blue;}
    }
    
}
.tab-bg-switching .cm-st-gallery{min-height: auto;}

.templt-radio-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    cursor: pointer;
    .custom-radio{
        top:-7px;
    }
}
.templt-label {
    font-size: 13px;
    font-weight: 400;
    color: $dark-grey-font;
    padding-left: 5px;
    padding-right: 23px;
}
.stok-selct{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin:25px 0;
}
.cm-icon{
    margin-right: 9px;
    &.upload  svg{
        width:20px;
        height: auto;
        fill:#000;
    }
    &.assets svg{
        margin-top: 2px;
        width:16px;
        height: auto;
        fill:#000;
    }
    &.stock svg{
        width:18px;
        height: auto;
        fill:#000;
    }
}

.replace-modal { 
    .stock-repo {
        min-height: 350px;
        max-height: 350px;
    }
}
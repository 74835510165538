
.dark{
    .login-left-part{
        background-color: $dark-grey-bg;
        border-right: 0;
    }
    .login-right-part{background-color: $dark-bg;}
    .login-cap{color:$white-font}
    .login-para{color:$dark-grey-font;}
    .custom-input{
        background-color: $slate-grey;
        color:$dark-grey-font;
        &::placeholder{
            color:rgba($dark-grey-font,.5);
        }
    }
    .cc-blk{background-color: $dark-bg;}
    .cc-heading{color:$white-font;}
    .cc-or-seperator{
        background-color:$dark-bg;
        color: $white-font;
    }
    .cc-csv-blk{background-color: $dark-grey-bg;}
    .custom-checkbox .checkmark { 
        border-color:transparent;
        background-color: $slate-grey;
    }
    .cp-subheading{color:$dark-grey-font;}
    .cc-wrapper .custom-input-holder .custom-label{color:$dark-grey-font;}
    .cc-para{color:$dark-grey-font;}
    .custom-checkbox .check-label{color:$dark-grey-font;}
    .cancel-btn{color:$dark-grey-font;}

    // css on 10/11/21
    .cm-wrapper{background-color:$dark-bg;}
    .cm-caption{color:$white-font;}
    .cm-subhead{color:$dark-grey-font;}
    .cm-left-blk{background-color:#262626;}
    .cm-right-blk{background-color: $dark-grey-bg;}
    .cm-add-label{color:$dark-grey-font;}
    .cm-add-label svg{fill:$dark-grey-font;}
    .cm-add-mod{border-top:1px solid rgba($dark-grey-font,.2);}
    .cm-mod-container{
        color:#f5f5f5;
        &.active{ background-color: #313131;}
        input{color:#f5f5f5;}
    }
    .cm-slide-blk{
        background-color: $slate-grey;
        border-bottom:3px solid #5a5a5b;
    }
    .panel-label{color:$dark-grey-font;}
    .cm-slide-container.active{color:$white-font;}
    .cm-slide-blk .cm-slide-wrapper .cm-slide-container.active{color:$white-font;} 
    .cm-screen-container.active{color:$white-font;}
    .cm-screen-blk .cm-screen-wrapper .cm-screen-container.active{color:$white-font;} 
    .cm-screen-blk{border-bottom:1px solid rgba($dark-grey-font,.2);}
    .panel-txtarea{
        background-color: $slate-grey;
        color:rgba($white-font,.8);
        &::placeholder{color:rgba($dark-grey-font,.5);}
        
    }
     .panel-count{
        color:rgba($white-font,.8);
    }
    .cm-full-lst-blk{border-bottom:1px solid rgba($dark-grey-font,.2);}
    .cm-full-lst-container.active{color:$white-font;}
    .cm-full-lst-blk .cm-full-lst-wrapper .cm-full-lst-container.active{color:$white-font;}
    .cm-del-icon{color:$dark-grey-font;}
    .cm-vs-wrapper{background-color: #262626;}
    .navtab-selector .nav-link.active .cm-asset-name{color:$white-font;}
    .navtab-selector .nav-link .cm-asset-name{color:$dark-grey-font;}
    .add-tag-blk{background-color: $slate-grey;}
    .add-tag-placeholder{color:rgba($dark-grey-font,.5);}
    .cm-style-wrapper{background-color: #262626;}
    .cm-style-spec{color:rgba($dark-grey-font,.6);}
    .cm-full-lst-blk .cm-full-lst-wrapper .cm-full-lst-container.active .cm-del-icon svg {fill:$dark-grey-font;}
    .color-picker .color-palette i{background-color: $dark-bg;}
    .custom-select .form-field{
        background-color: $slate-grey;
        color:$dark-grey-font;
        border:none;
    }
    .custom-select::after{border-color:$dark-grey-font transparent transparent transparent;}
    .cm-color-picker .edit-label{color:$dark-grey-font;}
    .color-picker .color-palette i svg{fill:$dark-grey-font;}
    .download-csv{color:$dark-grey-font;}
    // css on 15/11/21
    .pb-wrapper{background-color: $dark-grey-bg;}
    .pb-srch-customiser{
        border:0;
        background-color: $slate-grey;
        .srch-icon svg{
            stroke:$dark-grey-font;
        }
        .srch-inpt-holder .srch-input{
            background-color: transparent;
            color:$dark-grey-font;
        }
        .srch-holder::after{background-color: rgba($dark-grey-font,.5);}
    }
    .pb-header .pb-filter-label{color:$dark-grey-font;}
    .pb-wrapper {
        .pb-card{
            background-color:$slate-grey ;
            .pb-count{text-shadow: none;}
            .pb-desc{color:$dark-grey-font;}
        }
        .custom-progress{background-color: transparent;}
    }
    .pb-tbl-blk {
        .pb-tbl-wrapper{
            background-color: $slate-grey;
            border-color:transparent;
        }
        .pb-head-label{color:$dark-grey-font;}
        .pb-data-label{color:$dark-grey-font;}
        .pb-progress-bar{background-color: rgba($dark-grey-font,.2);}
        .failed-status{font-weight: 600;}
    }
    .arrow{
        .arrow-up{    border-bottom: 7px solid rgba($dark-grey-font,.9);}
        .arrow-down{    border-top: 7px solid rgba($dark-grey-font,.9);}
    } 
    .pb-stat-icon{
        svg{
            fill:rgba($dark-grey-font,.5);
        }
        &.download svg path{
            stroke:rgba($dark-grey-font,.5);
        }
    }
    .fs-vdo-blk{background-color: #000;}

    // 16 nov

    .srch-temp-blk{
        background-color: $dark-grey-bg;
        .srch-icon svg{
            stroke:$dark-grey-font;
        }
        .srch-input{
            color:$dark-grey-font;
            &::placeholder{
                color: rgba($dark-grey-font,.6);
            }
        }
        .srch-temp-wrapper{border-color: rgba($dark-grey-font,.5);}
    }

    .pagination-holder{
        li.pagination-nav {
            background: $slate-grey;
            color: $dark-grey-font;
            &:hover{
                color: $theme-blue;
            }
            &.active{
                border:1px solid $theme-blue;
                color:$theme-blue;
            }
            &.pagin-prev{
                svg{
                    fill:$dark-grey-font;
                }
            &:hover svg{fill:$theme-blue;}
            &.disabled svg{fill:rgba($dark-grey-font,.6);}
            }
            &.pagin-next{
                svg{
                    fill:$dark-grey-font;
                }
            &:hover svg{fill:$theme-blue;}
            &.disabled svg{fill:rgba($dark-grey-font,.6);}
            }
        }
    }
    .cm-slide-container.active .cm-del-icon svg {fill:$dark-grey-font;}
    .cm-screen-container.active .cm-del-icon svg {fill:$dark-grey-font}
    .cm-mod-container.active .cm-del-icon svg {
        fill: $dark-grey-font;
    }


    // 23 Dec 21
    .inp-txt {
        background-color: $slate-grey;
        color: $dark-grey-font;
        border: none;
        &::placeholder{color:$dark-grey-font;}
    }
    .tab-bg-switching{
        background-color: $dark-grey-bg;
    }
    .tab-assets{border-bottom:1px solid rgba($dark-grey-font,.2);}
    .inner-tab-assets{border-bottom:1px solid rgba($dark-grey-font,.2);}
    .custom-radio .checkmark:after {border-color:  $dark-grey-bg;}

    .cm-icon{
        &.upload  svg{
            fill:#fff;
        }
        &.assets svg{
            fill:#fff;
        }
        &.stock svg{
            fill:#fff;
        }
    }



}

.custom-modal{
    &.dark{
        .modal-body{background-color: $dark-grey-bg;}
        .modal-close{
            color:$dark-grey-font;
            &:hover{color:$white-font;}
        }
        .modal-caption{color:$white-font;}
        .modal-subcaption{color:$dark-grey-font;}
        .cp-img-holder{
            background: url("../../img/cc_dark.png");
            background-repeat: no-repeat;
            background-size: 100%;
        }
        .cp-heading{color: $white-font;}
        .custom-checkbox .checkmark { 
            border-color:transparent;
            background-color: $slate-grey;
        }
        .modal-close{
            svg{fill:rgba($white-font,.8);}
            &:hover{
                svg{fill:$white-font;}
            } 
        }
        .modal-content{
            background-color: transparent;
        }
        .temp-right-part{
            .temp-cap{color: $white-font;}
            .temp-subcap{color: $white-font;}
        }
        .dev-spec-wrapper{
            .dev-spec-container{
                &.active{
                    .dev-spec-label{
                        color:$white-font;
                    }
                }
            }
            .dev-spec-label{
                color:rgba($dark-grey-font,.6);
            }
        }
    }
}


// dark

.dark {

    .top-bar {
        background: #262626;
        .create-btn {
            background: $dark-grey-bg;
            color: $dark-grey-font;
            box-shadow: none;
            svg {
                stroke: $dark-grey-font;
                // margin-right: 5px;
            }
        }
        .topbar-menu {
            flex: 1;
            h3 {
                color: $dark-grey-font;
                &::before {
                    background: $slate-grey;
                }
            }
            input {
                color: $dark-grey-font;
                // border-bottom: 1px solid $slate-grey;
                border-bottom: 1px solid $dark-grey-font;
            }
            ul li {
                .notification svg {
                    stroke: $dark-grey-font;
                }
                .dropdown .dropdown-menu {
                    background: #1f2021;
                }
                .dropdown .dropdown-menu::before {
                    content: '';
                    border-bottom: 8px solid #1f2021;
                }
                .mode-change {
                    background: #141515;
                    border-radius: 0px 0px 10px 10px;
                }
                .dropdown .dropdown-menu .dropdown-header h6,
                .dropdown .dropdown-menu .dropdown-header p,
                .dropdown .dropdown-menu ul li a span,
                .dropdown .dropdown-menu .mode-change h6 {
                    color: $dark-grey-font;
                }
                .dropdown .dropdown-menu ul li a .dropdown-icon.stroke svg {
                    stroke: $dark-grey-font;
                }
                .dropdown .dropdown-menu ul li a .dropdown-icon.fill svg {
                    fill: $dark-grey-font;
                }
            }
        }
    }

    aside {
        background: $dark-grey-bg;
        box-shadow: none;
        .tab-content  {
            .accordion > .card {
                .card-body {
                    background: $dark-grey-bg;
                    box-shadow: none;
                    .card {
                        border-bottom: 1px solid #262727 !important;
                        .card-header a strong {
                            color: #6f7172;            
                        }
                    }
                }
                .card-header {
                    background: $slate-grey;
                    &:first-child {
                        border-bottom: 1px solid #262727 !important;
                    }
                    h5 a {
                            color: $dark-grey-font;
                    }
                    a {
                        color: $dark-grey-font !important;
                    }
                }
                p {
                    color: $dark-grey-font;
                }
            }
            .slide-wrap .slide-inner li {
                &:before {
                    border-left: 1px dashed #545454;
                }
                &.active {
                    .slide-list::before {
                        background: #468be8;
                    }
                }
                .slide-list::before {
                    content: '';
                    width: 14px;
                    height: 14px;
                    background: #1f2021;
                    border: 1px solid #545454;
                } 
                .slide-list::after {
                    border: 2px solid #1f2021;
                }
            }
        }
    }

    .divider {
        background: #1a1b1b;
    }
    .custom-range .output {
        background: $slate-grey;
        color: #C0C2C4;
        border: 0.5px solid $slate-grey;
    }
    .video-sec {
        background: #141515;
        .preview-sec .btn.btn-outline {
            background: $slate-grey;
            border: 0.5px solid $slate-grey;
            color: $dark-grey-font;
        }
        .video-inner .video-head h4 {
            color: $dark-grey-font;
            border-bottom: 0.5px solid $slate-grey;
        }
    }

    
    
    .right-sec  {
        .tab-view {
            .tab-content {
                background: #1f2021;
                .accordion > .card .card-body .card {
                    border-bottom: 0.5px solid rgba($slate-grey,0.5) !important;
                }
                .accordion > .card .card-body .card .card-header {
                    border-bottom: none !important;
                }
                .accordion > .card .card-header:first-child {
                    border-bottom: 1px solid rgba(#545454,0.5);
                }

                .accordion > .card:last-child .card-header {
                    border-bottom: none;
                }
                .accordion > .card .card-header {
                    background: $slate-grey;
                }
                .accordion > .card .card-inner {
                    background: #1f2021;
                }     
                .accordion > .card .card-header h5 a,
                .accordion > .card .card-inner .edit-label {
                    color: $dark-grey-font;
                }      
                .accordion > .card .card-inner .field-block .editor-list li:first-child {
                    border-left: 0.5px solid $slate-grey;
                } 
                .accordion > .card .card-inner .field-block .editor-list li {
                    border-top: 0.5px solid $slate-grey;
                    border-bottom: 0.5px solid $slate-grey;
                }
                .accordion > .card .card-inner .field-block .editor-list li:last-child {
                    border-right: 0.5px solid $slate-grey;
                }
                .accordion > .card .card-inner .field-block .img-upload .upload-btn {
                    background: #141515;
                    opacity: 0.75;
                    box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.15);
                }
                .accordion > .card .card-inner .field-block .img-upload .img-wrap {
                    border: 1px solid #161616;
                }
                .accordion > .card .card-inner .field-block .img-upload .upload-btn svg {
                    stroke: $dark-grey-font;
                }
                .accordion > .card .card-inner textarea.form-control {
                    background: $slate-grey;
                    border: 1px solid $slate-grey;
                    color: #C0C2C4;
                }
            }
            .tab-head {
                background: #1a1a1a;
                &:before {
                    opacity: 0.2;
                }
            }
        }
    }

    .back-btn {
        svg {
            fill: $light-gray-text;
        }
    }

    .Decoration-btn .editor-btn {
        background: $slate-grey;
        border: 0.5px solid $slate-grey;
        color: $dark-grey-font;
    }
    .Decoration-btn .editor-btn.active,
    .Decoration-btn .editor-btn:hover {
        background: #616161;
        border: 0.5px solid #616161;
        color: $dark-grey-font;
    }
    .editor-list li button {
        background: $slate-grey;
        border: 0.5px solid $slate-grey;
        color: $dark-grey-font;
    }
    .editor-list li button.active,
    .editor-list li button:hover {
        background: #616161;
        border: 0.5px solid #616161;
        color: $dark-grey-font;
    }
    .outline-i {
        stroke: $dark-grey-font;
    }
    .fill-i {
        fill: $dark-grey-font;
    }
    .save-apply p {
        background: #1F2021;        
        color: #F5F5F5;
    }
    .custom-number input {
        background-color: #2e2e2e;
        color: #c0c2c3;
        border: 0.5px solid #2e2e2e;
        min-height: 26px;
    }


    .custom-number input {
        background-color: #2e2e2e;
        color: #c0c2c3;
        border: 0.5px solid #2e2e2e;
        min-height: 26px;
    }
    .close-priview {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        background: #000;
        border-radius: 5px;
        svg {
            fill: #fff;
        }
    }
    &.replace-modal .srch-temp-blk p {
        color: #C0C2C3;
    }
    .btn-outline {
        background: #2e2e2e;
        border: 0.5px solid #2e2e2e;
        box-sizing: border-box;
        color: #c0c2c3;
        &:hover,
        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
            background: #2e2e2e;
            border: 0.5px solid #2e2e2e;
            box-sizing: border-box;
            color: #c0c2c3;
        }
    }
    
}


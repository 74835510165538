@mixin border-radius($radius){
    border-radius:$radius;
    -webkit-border-radius:$radius;
    -moz-border-radius:$radius;
    -ms-border-radius:$radius;
}
@mixin box-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $color: $default){
    box-shadow: $x-axis $y-axis $blur $color;
    -webkit-box-shadow: $x-axis $y-axis $blur $color;
    -moz-box-shadow: $x-axis $y-axis $blur $color;
    -ms-box-shadow: $x-axis $y-axis $blur $color;
}
@mixin position($position,$top:null,$left:null,$right:null,$bottom:null){
    position:$position;
    top:$top;
    left:$left;
    right:$right;
    bottom:$bottom;
}
@mixin transform($val1,$val2){
    -webkit-transform: translate($val1, $val2);
    -ms-transform: translate($val1, $val2);
    -moz-transform: translate($val1, $val2);
    transform: translate($val1, $val2);
}

@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
  }

  